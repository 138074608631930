


import React, { useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav } from 'react-bootstrap';

function Navigation() {
  const [navExpanded, setNavExpanded] = useState(false);

  return (
    <Navbar
      style={{ backgroundColor: '#92ace8' }}
      expand="lg"
      className="py-2 sticky-top justify-content-between text-white"
      expanded={navExpanded}
    >
      <LinkContainer to="/" style={{ marginLeft: '30px', font:'Poppins'}}>
        <Navbar.Brand >
          Archie ATS
        </Navbar.Brand>
      </LinkContainer>
      <div style={{ marginRight: '10px', font:"inherit"}}>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setNavExpanded(!navExpanded)} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav exact activeKey={window.location.pathname} onSelect={() => setNavExpanded(false)}>
            <LinkContainer  exact to="/">
              <Nav.Link>Accueil</Nav.Link>
            </LinkContainer>
            <LinkContainer  exact to="/dashboard">
              <Nav.Link>Tableau de bord</Nav.Link>
            </LinkContainer>
            <LinkContainer exact to="/ad-management">
              <Nav.Link>Gestion Publicités</Nav.Link>
            </LinkContainer>
            <LinkContainer exact to="/audience-management">
              <Nav.Link>Gestion Audiences</Nav.Link>
            </LinkContainer>
            <LinkContainer exact to="/analytics">
              <Nav.Link>Analytics</Nav.Link>
            </LinkContainer>
            <LinkContainer exact to="/login">
              <Nav.Link>Connexion</Nav.Link>
            </LinkContainer>
            <LinkContainer exact to="/profile">
              <Nav.Link>Documentation</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default Navigation;
