

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import LandingPage from './components/views/LandingPage';
import Dashboard from './components/views/Dashboard';
import AdManagement from './components/views/AdManagement';
import AudienceManagement from './components/views/AudienceManagement';
import Analytics from './components/views/Analytics';
import Navigation from './components/Navigation';
import Login from './components/views/Login';
import Profile from './components/views/Profile';

function App() {
  return (
    <Router>
      <Navigation />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/ad-management/*" element={<AdManagement />} />
        <Route path="/audience-management/*" element={<AudienceManagement />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
    </Router>
  );
}

export default App;
