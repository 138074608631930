


import React from 'react';
import { Container, Row, Col, Button, Card, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom'

function LandingPage() {
    return (
        <Container fluid className="p-5">
            {/* Header */}
            <Row className="text-dark py-5" style={{ backgroundColor: 'light', borderRadius: '10px' }}>
                <Col className="text-center color-dark">
                    <h1>Revitaliser la Publicité à la Télévision</h1>
                    <h6>Archie est le futur de la publicité à la télé : Ciblée, Efficace & Pertinente.</h6><br />
                    <Button as={Link} to="/dashboard" variant="light" size="lg">Tableau de bord</Button>
                </Col>
            </Row>

            {/* Features */}
            <Row className="my-5">
                <Col md={4}>
                    <Card className="text-white py-5 mb-5 text-center" style={{ backgroundColor: '#000c1f', borderRadius: '5px' }}>
                        <Card.Body>
                            <Card.Title>Ciblage Avancé</Card.Title>
                            <Card.Text>Avec Archie, les diffuseurs peuvent cibler les audiences comme jamais auparavant, touchant les bonnes personnes au bon moment. En effet, cette innovation semble primordiale pour la télévison afin de survivre à la forte attraction des plateformes en ligne.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="text-white py-5 mb-5 text-center" style={{ backgroundColor: '#000c1f', borderRadius: '5px' }}>
                        <Card.Body>
                            <Card.Title>Piloté par les Données</Card.Title>
                            <Card.Text>Obtenez des perspectives inégalées sur la performance de vos publicités à la télévision. Comprenez mieux votre audience et affinez vos stratégies afin de repondre au mieux aux besoins des annonceurs et des audiences. Les données d'analyses permettront d'affiner davantage vos stratégies pour garantir les meilleurs résultats.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="text-white py-5 mb-5 text-center" style={{ backgroundColor: '#000c1f', borderRadius: '5px' }}>
                        <Card.Body>
                            <Card.Title>Avant-gardiste</Card.Title>
                            <Card.Text>Archie est conçu pour l'avenir de la télévision. À mesure que la technologie évolue, nous évoluons aussi. Soyez prêt pour ce qui vous attend car ce n'est qu'une partie des fonctionnalités d'Archie ATS qui sont expérimentées dans cette Beta.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Detailed Description */}
            <Row className="my-5 text-dark p-4 text-center" style={{borderRadius: '10px', backgroundColor: '#92ace8'}}>
                <Col>
                    <h2 className="mb-4 ">Archie ! C'est quoi ?</h2>
                    <p>Archie n'est pas juste un autre outil de ciblage publicitaire. C'est un changement de paradigme pour l'industrie de la télévision. À l'ère des téléviseurs intelligents et de la transformation numérique, Archie comble le fossé entre le ciblage robuste des publicités en ligne et la portée étendue de la télévision.</p>
                    <p>Avec l'essor des plateformes de réseaux sociaux et de streaming, le déclin de la publicité télévisée traditionnelle semblait inévitable. Archie se présente comme un véritable acteur de changement. Notre plateforme est conçue pour autonomiser les diffuseurs, rendant leur contenu plus précieux en diffusant des publicités ciblées efficacement. En effet, les nombre de publicités diffusées augmente significativement avec notre logiciel qui segmente les audiences en groupe publicitaires pertinents. À mesure que l'industrie avance, Archie avance aussi tout en intégrant progressivement de nouvelles innovations qui permettront de redonner à la publicité à la télévison, les parts de marchés perdus aux nouvelles technologies d'internet.</p>
                </Col>
            </Row>

            {/* How It Works */}
            <Row className="my-15">
                <Col>
                    <h2 className="mb-4"> Comment ça marche ? </h2>
                    <ListGroup variant="flush">
                        <ListGroup.Item>
                            <h4>Étape 1: Obtenez votre accès</h4>
                            <p>Inscrivez-vous en tant que diffuseur et obtenez un accès instantané à la suite d'outils d'Archie.</p>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <h4>Étape 2: Gérez Vos Publicités</h4>
                            <p>Téléchargez, planifiez et gérez vos publicités télévisées en un seul endroit.</p>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <h4>Étape 3: Définissez Votre Audience</h4>
                            <p>Utilisez nos outils de ciblage avancés pour garantir que vos publicités atteignent les bonnes personnes.</p>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <h4>Étape 4: Plongez dans les Analytics</h4>
                            <p>Analysez les indicateurs de performance pour affiner votre stratégie publicitaire et maximiser le ROI.</p>
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>

            <Row>
                {/* Pricing */}
                <Col className="text-center">
                    <h2 className="bg-white text-dark">Souscription</h2>
                    <Card style={{ maxWidth: '320px', margin: '0 auto'}}>
                        <Card.Header className="bg-dark text-white">Abonnement</Card.Header>
                        <Card.Body>
                            <Card.Title>990€ / mois</Card.Title>
                            <Card.Text>Accès total à tous les outils d'Archie</Card.Text>
                            <Button as={Link} to="/ad-management/*" variant="primary">Accéder</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {/* Footer */}
            <Row className="text-white py-3 mt-5" style={{ backgroundColor: '#000c1f', borderRadius: '10px'}}>
                <Col className="text-center" style={{font:"Poppins"}}>
                    <p>&copy; {new Date().getFullYear()} Archie - ATS |  Par Zak. Tous droits réservés.</p>
                </Col>
            </Row>
        </Container>
    );
}

export default LandingPage;
