


import React from 'react';
import { Container, Form, Button, Row, Col, Card } from 'react-bootstrap';

function Login() {
  return (
    <Container className="mt-5">
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4">Mon Compte</h2>
              <Form>
                <Form.Group id="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" required />
                </Form.Group>
                <Form.Group id="password" className="mt-3">
                  <Form.Label>Mot de passe</Form.Label>
                  <Form.Control type="password" required />
                </Form.Group>
                <Button className="mt-3" type="submit">
                  Se connecter
                </Button>
              </Form>
              <div className="w-100 text-center mt-3">
                <span>vous n'avez pas de compte? <a href="/signup">Créer un compte</a></span>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
