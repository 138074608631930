



import React, { useState } from 'react';
import { Button, Form, Container } from 'react-bootstrap';

function AudienceManagement() {
    const initialAudiences = [
        "Audience Générale",
        "Audiences Géographiques",
        "Jeunes/Etudiants 18-25",
        "Ménagères de 50 ans",
        "LGBTQ+",
        "Personnes Agées",
        "Passion Mode",
        "Familles",
        "Professionnels",
        "Sportifs",
        "Regimes Alimentaires",
        "Personnes Handicapées",
        "Célibataires",
        "Jeunes Mamans",
        "Adeptes Technologies",
        "Voyageurs",
        "Automobilistes",
        "Motards",
        "Vacances en France",
        "Touristes",
    ];

    const [audiences, setAudiences] = useState(initialAudiences);
    const [newAudience, setNewAudience] = useState('');

    const handleAddAudience = () => {
        if (newAudience && !audiences.includes(newAudience)) {
            setAudiences(prev => [...prev, newAudience]);
            setNewAudience('');
        }
    };

    return (
        <div className="mt-4">
            <h2 className="text-center mb-4">Gestionnaire d'Audiences</h2>
            {/* Audience Creation */}
            <Container className="bg-light p-4 rounded mb-5 text-center">
                <Form.Group className="mb-3">
                    <Form.Label>Nom Audience</Form.Label>
                    <Form.Control
                        type="text"
                        value={newAudience}
                        onChange={e => setNewAudience(e.target.value)}
                        placeholder="Entrer votre Audience Personalisée"
                        className="rounded text-center"
                    />
                </Form.Group>
                <Button variant="primary" onClick={handleAddAudience}>
                    Ajouter Audience
                </Button>
            </Container>

            {/* List of Audiences */}
            <div className="d-flex flex-wrap justify-content-around">
                {audiences.map((audience, index) => (
                    <Button variant="dark" size="lg" className="rounded-pill m-2" key={index}>
                        {audience}
                    </Button>
                ))}
            </div>
        </div>
    );
}

export default AudienceManagement;
