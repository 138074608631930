



import React, { useState } from 'react';
import { Button, Form, Table, Row, Col, Container } from 'react-bootstrap';

function AdManagement() {
    const [ads, setAds] = useState([
        {
            name: 'Exemple Pub 1',
            type: 'vidéo',
            content: 'KFC',
            startDate: '2023-01-01',
            endDate: '2023-12-31',
            audience: 'Jeunes Hommes'
        },
        {
            name: 'Pub 2',
            type: 'Vidéo',
            content: 'Afflelou',
            startDate: '2023-02-01',
            endDate: '2023-10-31',
            audience: 'Personnes Agées'
        },
        {
            name: 'Pub 3',
            type: 'Vidéo',
            content: 'Gay Pride',
            startDate: '2023-02-01',
            endDate: '2023-10-31',
            audience: 'LGBTQ+'
        },
        {
            name: 'Pub 4',
            type: 'Vidéo',
            content: 'Mercedes Utilitaire',
            startDate: '2023-02-01',
            endDate: '2023-10-31',
            audience: 'Professionnels'
        },

    ]);

    const [adForm, setAdForm] = useState({
        name: '',
        type: 'Vidéo',
        content: '',
        startDate: '',
        endDate: '',
        audience: ''
    });

    const handleChange = (e) => {
        setAdForm({
            ...adForm,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setAds([...ads, adForm]);
        setAdForm({
            name: '',
            type: 'Vidéo',
            content: '',
            startDate: '',
            endDate: '',
            audience: ''
        });
    };

    return (
        <Container>
            <h2 className="text-center mb-5 mt-3">Gestionnaire de Publicités</h2>

            <Row className="justify-content-center">
                <Col md={6}>
                    <Form onSubmit={handleSubmit}  className='text-center'>
                        <Form.Group>
                            <Form.Label>Titre</Form.Label>
                            <Form.Control type="text" name="name" value={adForm.name} onChange={handleChange} required/>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Catégorie</Form.Label>
                            <Form.Control as="select" name="type" value={adForm.type} onChange={handleChange} required>
                                <option>Bannière</option>
                                <option>Vidéo</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Contenu / Media</Form.Label>
                            <Form.Control type="text" name="content" value={adForm.content} onChange={handleChange} placeholder="URL or Text" required/>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Date de Début</Form.Label>
                            <Form.Control type="date" name="startDate" value={adForm.startDate} onChange={handleChange} required/>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Date de Fin</Form.Label>
                            <Form.Control type="date" name="endDate" value={adForm.endDate} onChange={handleChange} required/>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Audience Cible</Form.Label>
                            <Form.Control type="text" name="audience" value={adForm.audience} onChange={handleChange} placeholder="exemple : Ménagère de 50 ans" required/>
                        </Form.Group>

                        <Button variant="primary" type="submit" block className='mt-2'>Créer Publicité</Button>
                    </Form>
                </Col>
            </Row>

            <Row className="mt-5">
                <Col>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Type</th>
                                <th>Contenu</th>
                                <th>Début</th>
                                <th>Fin</th>
                                <th>Audience</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ads.map((ad, index) => (
                                <tr key={index}>
                                    <td>{ad.name}</td>
                                    <td>{ad.type}</td>
                                    <td>{ad.content}</td>
                                    <td>{ad.startDate}</td>
                                    <td>{ad.endDate}</td>
                                    <td>{ad.audience}</td>
                                    <td>
                                        <Button variant="success" size="sm" className="mr-2 m-2">Modifier</Button>
                                        <Button variant="danger" size="sm">Effacer</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
}

export default AdManagement;
