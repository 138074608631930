


import React from 'react';
import { Card, Row, Col, Container, ProgressBar } from 'react-bootstrap';

function Dashboard() {
  return (
    <Container>
      <h2 className="text-center my-5">Tableau de Bord</h2>
      <Row>
        <Col md={4}>
          <Card className="mb-4 bg-dark text-white text-center">
            <Card.Body>
              <Card.Title>Total Pubs</Card.Title>
              <Card.Text>
                120
                <ProgressBar variant="info" now={65} className="mt-2" />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-4 bg-dark text-white text-center">
            <Card.Body>
              <Card.Title>Total Audiences</Card.Title>
              <Card.Text>
                19
                <ProgressBar variant="info" now={90} className="mt-2" />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-4 bg-dark text-white text-center">
            <Card.Body>
              <Card.Title>Campagnes Actives</Card.Title>
              <Card.Text>
                8
                <ProgressBar variant="info" now={30} className="mt-2" />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-4 bg-dark text-white text-center">
            <Card.Body>
              <Card.Title>Audiences Créées</Card.Title>
              <Card.Text>
                9
                <ProgressBar variant="info" now={40} className="mt-2" />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-4 bg-dark text-white text-center">
            <Card.Body>
              <Card.Title>Meilleures Audiences</Card.Title>
              <Card.Text>
                Personnes Agées | Ménagères de 50 ans | Célibataires
                <ProgressBar variant="info" now={98} className="mt-2" />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-4 bg-dark text-white text-center">
            <Card.Body>
              <Card.Title>Campagnes Terminées</Card.Title>
              <Card.Text>
                180
                <ProgressBar variant="info" now={100} className="mt-2" />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
