


import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const mockData = {
  totalReach: 58000,
  activeAudience: 42000,
  totalAdsServed: 15,
  audienceRetention: 84,  // in percentage
  peakViewershipTime: '20h30',
};

function Analytics() {
  return (
    <Container className="mt-4">
      <h2 className="text-center">Analyse des Publicités</h2>
      <Row className="mt-4">
        <Col md={4}>
          <Card className="bg-light text-dark text-center">
            <Card.Body>
              <Card.Title>Postes Atteints</Card.Title>
              <Card.Text>{mockData.totalReach} téléspectateurs</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="bg-dark text-white text-center">
            <Card.Body>
              <Card.Title>Postes Actifs</Card.Title>
              <Card.Text>{mockData.activeAudience} téléspectateurs</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="bg-light text-dark text-center">
            <Card.Body>
              <Card.Title>Nombre Diffusion</Card.Title>
              <Card.Text>{mockData.totalAdsServed} fois </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6}>
          <Card className="bg-dark text-white text-center">
            <Card.Body>
              <Card.Title>Taux de Retention</Card.Title>
              <Card.Text>{mockData.audienceRetention} %</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="bg-dark text-white text-center">
            <Card.Body>
              <Card.Title>Pic d'Audience</Card.Title>
              <Card.Text>{mockData.peakViewershipTime}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Analytics;
